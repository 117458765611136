import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  Modal,
  ScrollView,
  Image,
  Dimensions,
  Linking,
  Alert,
  Platform,
} from 'react-native';
import { firestore, auth, storage } from '../../firebaseConfig';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../App';
import {
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  orderBy,
  limit,
} from 'firebase/firestore';
import { Ionicons } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator'; // Added this import
import Animated, { 
  useSharedValue, 
  useAnimatedStyle, 
  withSpring 
} from 'react-native-reanimated';
import { useFocusEffect } from '@react-navigation/native';

type VendorProfileScreenProps = {
  navigation?: StackNavigationProp<RootStackParamList, 'VendorProfile'>;
  vendor?: any;
  isExplorer?: boolean;
  onClose?: () => void;
};

interface PostData {
  image: string;
  caption: string;
  price: string;
  aspectRatio: number;
  timestamp: number;
}

const getCurrencySymbolForLocation = (location: string | null): string => {
  const locationCurrencyMap: { [key: string]: string } = {
    Bali: 'IDR',
    Bangkok: 'THB',
    'Chiang Mai': 'THB',
    Copenhagen: 'DKK',
    Florence: 'EUR',
    London: 'GBP',
    Nice: 'EUR',
    'New York': 'USD',
    Paris: 'EUR',
    Tokyo: 'JPY',
  };
  return location ? locationCurrencyMap[location] || 'USD' : 'USD';
};

const confirmDelete = (item: string, callback: () => void) => {
  if (Platform.OS === 'web') {
    if (window.confirm(`Are you sure you want to delete ${item}?`)) {
      callback();
    }
  } else {
    Alert.alert(
      'Confirm Delete',
      `Are you sure you want to delete ${item}?`,
      [
        { text: 'Cancel', style: 'cancel' },
        { text: 'Delete', style: 'destructive', onPress: callback },
      ],
      { cancelable: true }
    );
  }
};

export default function VendorProfileScreen({
  navigation,
  vendor,
  isExplorer,
  onClose,
}: VendorProfileScreenProps) {
  // Profile & UI state
  const [vendorName, setVendorName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [googleMapsLocation, setGoogleMapsLocation] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const [newPostModalVisible, setNewPostModalVisible] = useState(false);
  const [newPostImage, setNewPostImage] = useState<string | null>(null);
  const [newPostCaption, setNewPostCaption] = useState('');
  const [newPostPrice, setNewPostPrice] = useState('');
  const [posts, setPosts] = useState<PostData[]>([]);
  const [selectedPost, setSelectedPost] = useState<PostData | null>(null);
  const [isFavorited, setIsFavorited] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [friendEmail, setFriendEmail] = useState('');
  const [friendsList, setFriendsList] = useState<Array<{ email: string, name?: string, profileImage?: string }>>([]);
  const [selectedFriend, setSelectedFriend] = useState<string | null>(null);
  const [loadingFriends, setLoadingFriends] = useState(true);
  const [visitedModalVisible, setVisitedModalVisible] = useState(false);
  const [aweyyRecommendation, setAweyyRecommendation] = useState<string | null>(null);

  const currentUser = auth.currentUser;

  const scale = useSharedValue(1);
  const animatedImageStyle = useAnimatedStyle(() => ({
    transform: [{ scale: scale.value }],
  }));

  // Fetch friends list for sharing from "vendorlist" where current user is the sharer.
  useEffect(() => {
    if (!currentUser) return;
    const fetchFriendsList = async () => {
      try {
        const vendorlistRef = collection(firestore, 'vendorlist');
        // Query documents where you're the sharer...
        const q1 = query(vendorlistRef, where('sharedBy', '==', currentUser.email));
        // ...and where you're the recipient.
        const q2 = query(vendorlistRef, where('sharedWith', '==', currentUser.email));
        const [snap1, snap2] = await Promise.all([getDocs(q1), getDocs(q2)]);
        const friendMap: { [email: string]: { email: string; name?: string; profileImage?: string } } = {};
  
        // For documents where you initiated the share,
        // the friend is in the "sharedWith" fields.
        snap1.forEach(docSnap => {
          const data = docSnap.data();
          const friendEmail = data.sharedWith;
          friendMap[friendEmail] = {
            email: friendEmail,
            name: data.sharedWithName || friendEmail,
            profileImage: data.sharedWithProfileImage || 'https://via.placeholder.com/50',
          };
        });
  
        // For documents where the friend initiated the share,
        // the friend is in the "sharedBy" fields.
        snap2.forEach(docSnap => {
          const data = docSnap.data();
          const friendEmail = data.sharedBy;
          friendMap[friendEmail] = {
            email: friendEmail,
            name: data.sharedByName || friendEmail,
            profileImage: data.sharedByProfileImage || 'https://via.placeholder.com/50',
          };
        });
  
        setFriendsList(Object.values(friendMap));
      } catch (error) {
        console.error('Error fetching friends list:', error);
      } finally {
        setLoadingFriends(false);
      }
    };
  
    fetchFriendsList();
  }, [currentUser, shareModalVisible]);
  

  // On mount, fetch vendor profile data and posts.
  useEffect(() => {
    const fetchVendorProfile = async () => {
      try {
        // Use vendor.userId OR vendor.vendorId (for shared vendors)
        const vendorId = vendor?.userId || vendor?.vendorId || currentUser?.uid;
        const vendorRef = doc(firestore, 'vendors', vendorId);
        const vendorSnap = await getDoc(vendorRef);
        if (vendorSnap.exists()) {
          const vendorData = vendorSnap.data();
          setVendorName(vendorData.vendorName);
          setDescription(vendorData.description);
          setSelectedLocation(vendorData.location);
          setSelectedCategory(vendorData.category);
          setGoogleMapsLocation(vendorData.googleMapsLocation || null);
          setProfilePicture(vendorData.profilePicture || null);
          if (vendorData.aweyyRecommendation && vendorData.aweyyRecommendation.text) {
            setAweyyRecommendation(vendorData.aweyyRecommendation.text);
          } else {
            setAweyyRecommendation(null);
          }
        }
      } catch (error) {
        setErrorMessage('Error fetching vendor profile');
      } finally {
        setLoading(false);
      }
    };
    
    const fetchVendorPostsForProfile = async () => {
      try {
        const vendorId = vendor?.userId || vendor?.vendorId || currentUser?.uid;
        const postsRef = collection(firestore, 'posts');
        const postsQuery = query(
          postsRef,
          where('vendorId', '==', vendorId),
          orderBy('timestamp', 'desc'),
          limit(20)
        );
        const querySnapshot = await getDocs(postsQuery);
        const vendorPosts: PostData[] = [];
        querySnapshot.forEach((docSnap) => {
          vendorPosts.push(docSnap.data() as PostData);
        });
        setPosts(vendorPosts);
      } catch (error) {
        console.error('Error fetching vendor posts:', error);
      }
    };

    const checkIfFavorited = async () => {
      if (isExplorer && currentUser && vendor) {
        try {
          const userRef = doc(firestore, 'users', currentUser.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const userData = userSnap.data();
            if (userData.favorites && userData.favorites.includes(vendor.userId)) {
              setIsFavorited(true);
            }
          }
        } catch (error) {
          console.error('Error checking if vendor is favorited:', error);
        }
      }
    };

    fetchVendorProfile();
    fetchVendorPostsForProfile();
    checkIfFavorited();
  }, [vendor, currentUser, isExplorer]);

  // Also update vendor data when the screen is refocused.
  useFocusEffect(
    React.useCallback(() => {
      const refreshVendorProfile = async () => {
        try {
          const vendorId = vendor?.userId || vendor?.vendorId || currentUser?.uid;
          const vendorRef = doc(firestore, 'vendors', vendorId);
          const vendorSnap = await getDoc(vendorRef);
          if (vendorSnap.exists()) {
            const vendorData = vendorSnap.data();
            setVendorName(vendorData.vendorName);
            setDescription(vendorData.description);
            setSelectedLocation(vendorData.location);
            setSelectedCategory(vendorData.category);
            setGoogleMapsLocation(vendorData.googleMapsLocation || null);
            setProfilePicture(vendorData.profilePicture || null);
            if (vendorData.aweyyRecommendation && vendorData.aweyyRecommendation.text) {
              setAweyyRecommendation(vendorData.aweyyRecommendation.text);
            } else {
              setAweyyRecommendation(null);
            }
          }
        } catch (error) {
          setErrorMessage('Error refreshing vendor profile');
        }
      };
      refreshVendorProfile();
    }, [currentUser, vendor, isExplorer])
  );

  const handleAddToFavorites = async () => {
    if (currentUser && vendor) {
      const userRef = doc(firestore, 'users', currentUser.uid);
      const vendorRef = doc(firestore, 'vendors', vendor.userId);
      try {
        if (isFavorited) {
          await updateDoc(userRef, { favorites: arrayRemove(vendor.userId) });
          await updateDoc(vendorRef, { favoriteCount: increment(-1) });
          setIsFavorited(false);
        } else {
          await updateDoc(userRef, { favorites: arrayUnion(vendor.userId) });
          await updateDoc(vendorRef, { favoriteCount: increment(1) });
          setIsFavorited(true);
        }
      } catch (error) {
        console.error('Error updating favorites:', error);
      }
    }
  };

  const handleSavePost = async () => {
    if (!newPostImage) {
      setErrorMessage("Please upload an image");
      return;
    }
    if (!currentUser) {
      setErrorMessage("User not authenticated");
      return;
    }
    try {
      const storageRef = ref(storage, `vendorPosts/${currentUser.uid}/${Date.now()}`);
      const response = await fetch(newPostImage);
      const blob = await response.blob();
      await uploadBytes(storageRef, blob);
      const downloadUrl = await getDownloadURL(storageRef);
      const imageAspect = await new Promise<number>((resolve, reject) => {
        Image.getSize(
          newPostImage,
          (width, height) => resolve(height / width),
          (error) => reject(error)
        );
      });
      const newPostData: PostData & {
        caption: string;
        price: string;
        image: string;
        vendorId: string;
        vendorName: string;
        location: string | null;
        category: string | null;
      } = {
        image: downloadUrl,
        caption: newPostCaption,
        price: newPostPrice,
        aspectRatio: imageAspect,
        timestamp: Date.now(),
        vendorId: currentUser.uid,
        vendorName: vendorName,
        location: selectedLocation,
        category: selectedCategory,
      };
      await addDoc(collection(firestore, 'posts'), newPostData);
      setPosts((prevPosts) => [newPostData, ...prevPosts]);
      setNewPostModalVisible(false);
      setNewPostImage(null);
      setNewPostCaption('');
      setNewPostPrice('');
    } catch (error) {
      console.error("Error saving post:", error);
      setErrorMessage("Error saving post");
    }
  };

  const handleDeletePost = (post: PostData & { image: string; caption: string }) => {
    confirmDelete(`the post "${post.caption}"`, async () => {
      if (currentUser) {
        try {
          const storageRef = ref(storage, post.image);
          await deleteObject(storageRef);
          const postsRef = collection(firestore, 'posts');
          const q = query(
            postsRef,
            where('vendorId', '==', currentUser.uid),
            where('image', '==', post.image)
          );
          const qs = await getDocs(q);
          qs.forEach(async (docSnap) => {
            await deleteDoc(doc(firestore, 'posts', docSnap.id));
          });
          setPosts((prev) => prev.filter((p) => p.image !== post.image));
        } catch (error) {
          console.error('Error deleting post:', error);
        }
      }
    });
  };

  const handlePostClick = (post: PostData) => {
    setSelectedPost(post);
  };

  const closeModal = () => {
    setSelectedPost(null);
  };

  const getGridColumns = () => {
    const screenWidth = Dimensions.get('window').width;
    return screenWidth > 768 ? 4 : 2;
  };

  const goBackOrClose = () => {
    if (isExplorer) {
      onClose?.();
    } else {
      navigation?.goBack();
    }
  };

  const handlePostImagePicker = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      quality: 0.3,
    });
    if (!result.canceled) {
      const manipulated = await ImageManipulator.manipulateAsync(
        result.assets[0].uri,
        [{ resize: { width: 800 } }],
        { compress: 0.7, format: ImageManipulator.SaveFormat.JPEG }
      );
      setNewPostImage(manipulated.uri);
    }
  };

  // --- NEW: Updated handleShareVendor using the "vendorlist" collection ---
  const handleShareVendor = async () => {
    const emailToShare = selectedFriend || friendEmail.trim().toLowerCase();
    if (!emailToShare || !currentUser || !vendor) {
      Alert.alert('Error', 'Please enter a valid email.');
      return;
    }
    try {
      const usersRef = collection(firestore, 'users');
      const q = query(usersRef, where('email', '==', emailToShare));
      const querySnapshot = await getDocs(q);
      const currentUserDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
      const currentUserData = currentUserDoc.exists() ? currentUserDoc.data() : {};
      if (!currentUser.email) {
        throw new Error('Current user email is missing');
      }
      // Build the share object.
      const vendorDataObj = {
        vendorId: vendor.userId || vendor.vendorId,  // Use vendorId from shared doc if available
        vendorName: vendor.vendorName,
        vendorDescription: vendor.description,
        vendorProfileImage: vendor.profilePicture || vendor.vendorProfileImage || null,
        location: selectedLocation || 'Unknown location',
        category: selectedCategory || null,
        sharedBy: currentUser.email.toLowerCase(),
        sharedByName: currentUserData.name || 'Unknown',
        sharedByProfileImage: currentUserData.profileImage || 'https://via.placeholder.com/50',
        sharedWith: emailToShare,
        // Save friend details if they exist.
        sharedWithName: querySnapshot.empty ? emailToShare : querySnapshot.docs[0].data().name || emailToShare,
        sharedWithProfileImage: querySnapshot.empty ? 'https://via.placeholder.com/50' : querySnapshot.docs[0].data().profileImage || 'https://via.placeholder.com/50',
        timestamp: Date.now(),
        isUser: !querySnapshot.empty,
      };

      // Write to "vendorlist" collection.
      await addDoc(collection(firestore, 'vendorlist'), vendorDataObj);

      // Send email/invitation.
      if (!querySnapshot.empty) {
        await addDoc(collection(firestore, 'mail'), {
          to: emailToShare,
          message: {
            subject: 'Vendor Shared with You on Aweyy!',
            html: `
              <html>
                <body style="font-family: Arial, sans-serif; background-color: #f9f9f9; margin: 0; padding: 0;">
                  <div style="max-width: 600px; margin: auto; background: #ffffff; border-radius: 8px; overflow: hidden; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">
                    <div style="background: #ffffff; padding: 20px; text-align: center;">
                      <img src="https://firebasestorage.googleapis.com/v0/b/aweyy-bdbf6.appspot.com/o/publicimages%2Flogo2.png?alt=media" alt="Aweyy Logo" style="width: 150px;">
                    </div>
                    <div style="padding: 20px;">
                      <h1 style="color: #333;">Vendor Shared with You on Aweyy!</h1>
                      <p style="color: #555;">
                        <strong>${currentUser.email}</strong> has shared a vendor with you on Aweyy!
                      </p>
                      <h2 style="color: #333;">${vendor.vendorName}</h2>
                      <p style="color: #555;">${vendor.description}</p>
                      <a href="https://aweyy.com" style="display: inline-block; margin-top: 20px; padding: 12px 24px; background-color: #b6cc9b; color: #fff; text-decoration: none; border-radius: 25px;">
                        View Vendor Profile
                      </a>
                      <p style="color: #555; margin-top: 20px;">Best regards,<br>The Aweyy Team</p>
                      <img src="https://firebasestorage.googleapis.com/v0/b/aweyy-bdbf6.appspot.com/o/publicimages%2FDownloadbanner.png?alt=media" alt="Download App" style="width: 100%; border-radius: 8px; margin: 20px 0;">
                    </div>
                  </div>
                </body>
              </html>
            `,
            text: `
              Hi there,
              
              ${currentUser.email} has shared a vendor with you on Aweyy!
              
              Vendor: ${vendor.vendorName}
              
              ${vendor.description}
              
              Visit https://aweyy.com to view the vendor profile.
              
              Best regards,
              The Aweyy Team
            `,
          },
        });
        Alert.alert('Success', `Vendor shared with ${emailToShare}.`);
      } else {
        const confirmMessage =
          'This email does not belong to an existing user. Do you want to send an invitation?';
        const confirmed =
          Platform.OS === 'web'
            ? window.confirm(confirmMessage)
            : await new Promise<boolean>((resolve) => {
                Alert.alert(
                  'User Not Found',
                  confirmMessage,
                  [
                    { text: 'Cancel', style: 'cancel', onPress: () => resolve(false) },
                    { text: 'Send Invitation', onPress: () => resolve(true) },
                  ],
                  { cancelable: true }
                );
              });
        if (confirmed) {
          await addDoc(collection(firestore, 'mail'), {
            to: emailToShare,
            message: {
              subject: "You're Invited to Aweyy!",
              from: 'Heyy From Aweyy App <heyy@aweyy.com>',
              html: `
                <html>
                  <body style="font-family: Arial, sans-serif; background-color: #f9f9f9; margin: 0; padding: 0;">
                    <div style="max-width: 600px; margin: auto; background: #ffffff; border-radius: 8px; overflow: hidden; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">
                      <div style="background: #ffffff; padding: 20px; text-align: center;">
                        <img src="https://firebasestorage.googleapis.com/v0/b/aweyy-bdbf6.appspot.com/o/publicimages%2Flogo2.png?alt=media" alt="Aweyy Logo" style="width: 150px;">
                      </div>
                      <div style="padding: 20px;">
                        <h1 style="color: #333;">You're Invited to Aweyy!</h1>
                        <p style="color: #555;">
                          <strong>${currentUser.email}</strong> wants to share a place with you on Aweyy!
                        </p>
                        <a href="https://aweyy.com" style="display: inline-block; margin-top: 20px; padding: 12px 24px; background-color: #b6cc9b; color: #fff; text-decoration: none; border-radius: 25px;">
                          Sign Up Now
                        </a>
                        <p style="color: #555; margin-top: 20px;">Best regards,<br>The Aweyy Team</p>
                        <img src="https://firebasestorage.googleapis.com/v0/b/aweyy-bdbf6.appspot.com/o/publicimages%2FDownloadbanner.png?alt=media" alt="Download App" style="width: 100%; border-radius: 8px; margin: 20px 0;">
                      </div>
                    </div>
                  </body>
                </html>
              `,
              text: `
                Hi there,
                
                ${currentUser.email} wants to share a place with you on Aweyy!
                
                Please sign up at https://aweyy.com to view more details.
                
                Best regards,
                The Aweyy Team
              `,
            },
          });
          Alert.alert('Invitation Sent', `An email has been sent to ${emailToShare}.`);
        }
      }
      // Ensure the modal is closed after sharing
      setShareModalVisible(false);
      setFriendEmail('');
      setSelectedFriend(null);
    } catch (error) {
      console.error('Error sharing vendor:', error);
      Alert.alert('Error', 'There was a problem sharing the vendor.');
      setShareModalVisible(false);
    }
  };

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#b6cc9b" />
      </View>
    );
  }

  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        contentContainerStyle={styles.scrollContainer}
        style={[styles.scrollView, { height: Dimensions.get('window').height }]}
      >
        <View style={styles.profileContainer}>
          <TouchableOpacity style={styles.topCloseButton} onPress={goBackOrClose}>
            <Ionicons name={isExplorer ? "close" : "arrow-back"} size={30} color="#4A4A4A" />
          </TouchableOpacity>

          <View style={styles.topRightButton}>
            <TouchableOpacity style={styles.favoriteButton} onPress={handleAddToFavorites}>
              <Ionicons name={isFavorited ? 'heart' : 'heart-outline'} size={32} color="#555" />
            </TouchableOpacity>
          </View>

          <View style={styles.profileHeader}>
            {profilePicture ? (
              <Image source={{ uri: profilePicture }} style={styles.profileImage} resizeMode="contain" />
            ) : (
              <Ionicons name="camera" size={100} color="#b6cc9b" />
            )}
          </View>

          <Text style={styles.vendorName}>{vendorName}</Text>
          <Text style={styles.description}>{description}</Text>

          <View style={styles.infoRow}>
            <Ionicons name="location-outline" size={20} color="#4A4A4A" />
            <Text style={styles.infoText}>{selectedLocation}</Text>
          </View>
          
          {aweyyRecommendation && (
            <View style={styles.visitedBadgeContainer}>
              <TouchableOpacity onPress={() => setVisitedModalVisible(true)}>
                <View style={styles.visitedBadge}>
                  <Ionicons name="rocket-outline" size={20} color="#FFF" />
                </View>
              </TouchableOpacity>
              <Text style={styles.visitedBadgeLabel}>Visited by Aweyy</Text>
            </View>
          )}

          <Modal
            visible={visitedModalVisible}
            transparent
            animationType="slide"
            onRequestClose={() => setVisitedModalVisible(false)}
          >
            <View style={styles.modalOverlay}>
              <View style={styles.modalContainer}>
                <Text style={styles.modalTitle}>Visited by Aweyy</Text>
                <Text style={styles.modalContentText}>
                  {aweyyRecommendation}
                </Text>
                <TouchableOpacity style={styles.cancelButton} onPress={() => setVisitedModalVisible(false)}>
                  <Text style={styles.cancelButtonText}>Close</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>

          {isExplorer && (
            <>
              <View style={styles.buttonRow}>
                <TouchableOpacity style={styles.sharedListButton} onPress={() => setShareModalVisible(true)}>
                  <Text style={styles.buttonText}>Add to Shared List</Text>
                </TouchableOpacity>
              </View>
              <Modal visible={shareModalVisible} transparent animationType="slide">
                <View style={styles.modalOverlay}>
                  <View style={styles.modalContainer}>
                    <Text style={styles.modalTitle}>Share with a Friend</Text>
                    {!loadingFriends && friendsList.length > 0 && (
                      <>
                        <Text style={styles.friendsListTitle}>Share with existing friends:</Text>
                        {friendsList.map((friend, index) => (
                          <TouchableOpacity
                            key={index}
                            style={[
                              styles.friendButton,
                              selectedFriend === friend.email ? styles.selectedFriendButton : undefined,
                            ]}
                            onPress={() => setSelectedFriend(friend.email)}
                          >
                            <Image
                              source={{ uri: friend.profileImage }}
                              style={{ width: 30, height: 30, borderRadius: 15, marginRight: 8 }}
                            />
                            <Text style={styles.friendButtonText}>{friend.name}</Text>
                          </TouchableOpacity>
                        ))}
                        <Text style={styles.orText}>or</Text>
                      </>
                    )}
                    <TextInput
                      placeholder="Enter friend's email"
                      placeholderTextColor="#666"
                      style={styles.input}
                      value={friendEmail}
                      onChangeText={setFriendEmail}
                      editable={!selectedFriend}
                    />
                    <TouchableOpacity style={styles.saveButton} onPress={handleShareVendor}>
                      <Text style={styles.saveButtonText}>Share</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.cancelButton} onPress={() => setShareModalVisible(false)}>
                      <Text style={styles.cancelButtonText}>Cancel</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </Modal>
            </>
          )}

          {googleMapsLocation && (
            <TouchableOpacity style={styles.mapsButton} onPress={() => Linking.openURL(googleMapsLocation)}>
              <Text style={styles.mapsButtonText}>View on Google Maps</Text>
              <Ionicons name="map-outline" size={16} color="#333" />
            </TouchableOpacity>
          )}

          {!isExplorer && (
            <>
              <TouchableOpacity style={styles.button} onPress={() => navigation?.navigate('Edit Vendor Profile')}>
                <Text style={styles.buttonText}>Edit Profile</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.button} onPress={() => setNewPostModalVisible(true)}>
                <Text style={styles.buttonText}>New Post</Text>
              </TouchableOpacity>
            </>
          )}

          <View style={styles.postsGrid}>
            {posts.map((post, index) => (
              <View key={index} style={[styles.postCard, { width: `${100 / getGridColumns() - 2}%` }]}>
                <TouchableOpacity onPress={() => handlePostClick(post)}>
                  <Image
                    source={{ uri: post.image }}
                    style={[
                      styles.postImage,
                      {
                        width: '100%',
                        height: Math.min(
                          Dimensions.get('window').width / (post.aspectRatio || 1),
                          300
                        ),
                      },
                    ]}
                    resizeMode="cover"
                  />
                </TouchableOpacity>
                <View style={styles.postInfo}>
                  {post.caption && <Text style={styles.postCaption}>{post.caption}</Text>}
                  {post.price && (
                    <Text style={styles.postPrice}>
                      {getCurrencySymbolForLocation(selectedLocation)} {post.price}
                    </Text>
                  )}
                </View>
                {!isExplorer && (
                  <TouchableOpacity style={styles.deleteButton} onPress={() => handleDeletePost(post)}>
                    <Text style={styles.deleteButtonText}>Delete</Text>
                  </TouchableOpacity>
                )}
              </View>
            ))}
          </View>
        </View>
      </ScrollView>

      {newPostModalVisible && (
        <Modal visible={newPostModalVisible} animationType="slide" transparent>
          <View style={styles.modalOverlay}>
            <View style={styles.modalContainer}>
              <Text style={styles.modalTitle}>New Post</Text>
              <TouchableOpacity style={styles.newPostImagePicker} onPress={handlePostImagePicker}>
                {newPostImage ? (
                  <Image source={{ uri: newPostImage }} style={styles.newPostImagePreview} resizeMode="cover" />
                ) : (
                  <Ionicons name="camera" size={50} color="#b6cc9b" />
                )}
              </TouchableOpacity>
              <TextInput
                style={styles.input}
                placeholder="Caption"
                placeholderTextColor="#666"
                value={newPostCaption}
                onChangeText={setNewPostCaption}
              />
              <TextInput
                style={styles.input}
                placeholder="Price"
                placeholderTextColor="#666"
                value={newPostPrice}
                onChangeText={setNewPostPrice}
              />
              <TouchableOpacity style={styles.saveButton} onPress={handleSavePost}>
                <Text style={styles.saveButtonText}>Save Post</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.cancelButton} onPress={() => setNewPostModalVisible(false)}>
                <Text style={styles.cancelButtonText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      )}

      {selectedPost && (
        <Modal 
          visible={true} 
          animationType="slide" 
          transparent 
          onRequestClose={closeModal}
        >
          <View style={styles.fullModalOverlay}>
            <Animated.Image
              source={{ uri: selectedPost.image }}
              style={[styles.fullImage, animatedImageStyle]}
              resizeMode="contain"
            />
            <TouchableOpacity 
              style={styles.modalCloseButton} 
              onPress={closeModal}
            >
              <Ionicons name="close" size={30} color="#fff" />
            </TouchableOpacity>
          </View>
        </Modal>
      )}
    </View>
  );
}

const buttonStyle = (activeTab: string, tab: string) => ({
  backgroundColor: activeTab === tab ? '#b6cc9b' : '#FFFFFF',
  paddingVertical: 12,
  paddingHorizontal: 30,
  borderRadius: 15,
  marginHorizontal: 5,
  borderWidth: 1,
  borderColor: '#b6cc9b',
});

const buttonTextStyle = (activeTab: string, tab: string) => ({
  color: activeTab === tab ? '#000000' : '#000000',
  fontWeight: '600' as '600',
  fontSize: 16,
});

const styles = StyleSheet.create({
  scrollContainer: { backgroundColor: '#fff', paddingBottom: 100, flexGrow: 1 },
  scrollView: { height: Dimensions.get('window').height },
  profileContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
    backgroundColor: '#fff',
    paddingTop: 100,
    paddingBottom: 50,
    minHeight: '100%',
  },
  profileHeader: { backgroundColor: '#fff', borderRadius: 100, width: 150, height: 150, justifyContent: 'center', alignItems: 'center', marginBottom: 20 },
  profileImage: { width: 150, height: 150, borderRadius: 100 },
  vendorName: { fontSize: 28, fontWeight: 'bold', color: '#4A4A4A', marginBottom: 10, textAlign: 'center' },
  description: { fontSize: 18, color: '#666', marginBottom: 20, textAlign: 'center' },
  infoRow: { flexDirection: 'row', alignItems: 'center', marginBottom: 10 },
  infoText: { fontSize: 18, color: '#4A4A4A', marginLeft: 10 },
  button: { backgroundColor: '#b6cc9b', paddingVertical: 12, paddingHorizontal: 30, borderRadius: 15, alignItems: 'center', marginVertical: 10, width: '80%' },
  buttonText: { color: '#333', fontWeight: '700', fontSize: 15 },
  cancelButton: { backgroundColor: '#F0F0F0', paddingVertical: 10, borderRadius: 15, alignItems: 'center', width: '100%' },
  cancelButtonText: { fontSize: 16, color: '#333', fontWeight: '600' as '600' },
  topCloseButton: { position: 'absolute', top: 40, left: 20, zIndex: 10 },
  topRightButton: { position: 'absolute', top: 40, right: 20, zIndex: 10 },
  favoriteButton: { padding: 10, alignItems: 'center', justifyContent: 'center' },
  buttonRow: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginVertical: 10 },
  sharedListButton: { backgroundColor: '#b6cc9b', paddingVertical: 12, paddingHorizontal: 15, borderRadius: 15, alignItems: 'center' },
  postsGrid: { flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%' },
  postCard: { backgroundColor: '#fff', borderRadius: 15, overflow: 'hidden', marginBottom: 15, shadowColor: '#000', shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.2, shadowRadius: 4, elevation: 5 },
  postImage: { width: '100%', borderRadius: 10 },
  postInfo: { padding: 5, alignItems: 'center' },
  postCaption: { fontSize: 14, color: '#4A4A4A', textAlign: 'center' },
  postPrice: { fontSize: 16, color: '#555', textAlign: 'center', fontWeight: 'bold', marginTop: 5 },
  deleteButton: { backgroundColor: '#FF5C5C', paddingVertical: 6, paddingHorizontal: 15, borderRadius: 8, marginTop: 5, width: '40%', alignSelf: 'flex-end' },
  deleteButtonText: { color: '#333', fontWeight: '600' as '600', fontSize: 14, textAlign: 'left' },
  newPostImagePicker: { width: 150, height: 150, justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff', borderWidth: 2, borderColor: '#b6cc9b', borderRadius: 15, marginBottom: 15 },
  newPostImagePreview: { width: '100%', height: '100%', borderRadius: 15 },
  modalOverlay: { flex: 1, justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.5)' },
  modalContainer: { backgroundColor: '#FFF', borderRadius: 20, padding: 20, marginHorizontal: 10, maxHeight: '80%', alignItems: 'center' },
  modalTitle: { fontSize: 24, fontWeight: '700' as '700', marginBottom: 20, textAlign: 'center' },
  input: { height: 50, width: '90%', backgroundColor: '#fff', borderColor: '#b6cc9b', borderWidth: 2, borderRadius: 15, paddingHorizontal: 15, marginBottom: 20, textAlign: 'center' },
  saveButton: { backgroundColor: '#b6cc9b', paddingVertical: 15, borderRadius: 15, alignItems: 'center', marginBottom: 20, width: '90%' },
  saveButtonText: { color: '#333', fontWeight: '600' as '600' },
  fullImage: { width: '100%', height: 300, marginBottom: 15 },
  fullCaption: { fontSize: 18, color: '#4A4A4A', marginVertical: 10, textAlign: 'center' },
  fullPrice: { fontSize: 18, color: '#555', fontWeight: 'bold' },
  mapsButton: { backgroundColor: '#fff', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingVertical: 12, borderRadius: 15, borderWidth: 2, borderColor: '#333', marginVertical: 10, width: '60%', marginBottom: 25 },
  mapsButtonText: { color: '#333', fontWeight: '500' as '500', fontSize: 16, marginRight: 10 },
  friendsListTitle: { fontSize: 18, fontWeight: '600' as '600', marginBottom: 10 },
  friendButton: { backgroundColor: '#FFF0F6', padding: 10, borderRadius: 15, marginBottom: 5, flexDirection: 'row', alignItems: 'center' },
  selectedFriendButton: { borderWidth: 2, borderColor: '#b6cc9b' },
  friendButtonText: { color: '#4A4A4A', fontWeight: '600' as '600' },
  orText: { marginVertical: 10, textAlign: 'center', color: '#666' },
  loadingContainer: { flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' },
  fullModalOverlay: { flex: 1, backgroundColor: '#000', justifyContent: 'center', alignItems: 'center' },
  modalCloseButton: { position: 'absolute', top: 40, right: 20 },
  visitedBadgeContainer: { marginVertical: 15, alignItems: 'center' },
  visitedBadge: { width: 25, height: 25, borderRadius: 20, backgroundColor: '#444', justifyContent: 'center', alignItems: 'center', elevation: 4 },
  visitedBadgeLabel: { marginTop: 5, fontSize: 10, color: '#4A4A4A', fontWeight: '600' },
  modalContentText: { fontSize: 16, color: '#555', textAlign: 'center', marginVertical: 20 },
});